<template>
  <div id="apply-button">
    <button @click="applyRedirect" class="font-roboto text-black px-16 py-2 rounded-3xl sm:text-lg"
    :class="{'bg-white': startRecruiting, 'bg-light_grey': !startRecruiting}">
      {{$t("apply.hero.applyButtonText")}}
    </button>
  </div>
</template>

<script>
export default {
  props: ["startRecruiting"],
  methods: {
    applyRedirect() {
      if (this.startRecruiting) {
        window.location.href = "https://forms.gle/YmR63HJFLhkCUEj4A";
      }
    },
  },
};
</script>
